import base64 from '@/utils/base64.js'
import { sendSock } from '@/websocket/websocket'

/**
 * 当前时间戳加随机数生成taskId
 */
function generateTaskId() {
  return new Date().getTime() * 10000 + Math.random() * 10000
}

// state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  // 告诉server退出登录
  // 返回  DeviceExitNotice
  AccountLogoutNotice({ rootGetters, commit }) {
    const msg = {
      Id: 3054,
      MsgType: 'AccountLogoutNotice',
      Content: {
        UnionId: Number(rootGetters.currentUser.UnionId),
        AccountType: 2
      },
      AccessToken: rootGetters.token
    }
    commit('SEND_SOCK', msg)
  },
  // 从sdk 获取微信列表
  // 返回  GetWeChatsRsp
  GetWeChatsReq({ rootGetters, commit }) {
    const msg = {
      Id: 3035,
      MsgType: 'GetWeChatsReq',
      Content: {
        AccountType: rootGetters.currentUser.AccountType,
        UnionId: rootGetters.currentUser.UnionId
      }
    }
    commit('SEND_SOCK', msg)
  },
  // server 选择微信登录 弃用
  // 返回  WeChatLoginNoticeResp
  WeChatLoginNotice({ rootGetters, commit }, wechats) {
    const msg = {
      Id: 3055,
      MsgType: 'WeChatLoginNotice',
      Content: {
        SupplierId: rootGetters.currentUser.SupplierId,
        UnionId: rootGetters.currentUser.UnionId,
        AccountType: rootGetters.currentUser.AccountType,
        WeChats: wechats
      }
    }
    commit('SEND_SOCK', msg)
  },
  // =============================================================================
  // 以下是 wxtools页面相关的sdk
  // =============================================================================
  // sdk 获取微信的二维码
  // 返回  PullWeChatQrCodeTaskResultNotice
  PullWeChatQrCodeTask({ commit }, wechatId) {
    const msg = {
      Id: 1079,
      MsgType: 'PullWeChatQrCodeTask',
      Content: {
        WeChatId: wechatId
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 手机重启|上传日志|清除微信视频缓存 返回 TaskResultNotice
  PhoneActionTask({ commit }, command) {
    const msg = {
      Id: 1223,
      MsgType: 'PhoneActionTask',
      Content: {
        WeChatId: command.wechatId,
        // Imei: 2, // 备用，用wxid或imei来定位手机
        Action: command.action, // 指令
        // StrParam: 4, // 字符串参数，后续扩展用
        // IntParam: 5, // 整型参数，后续扩展用
        TaskId: generateTaskId().toString()
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 获取好友申请列表 返回 FriendAddReqListNotice
  PullFriendAddReqListTask({ commit }, wechatId) {
    const msg = {
      Id: 1234,
      MsgType: 'PullFriendAddReqListTask',
      Content: {
        WeChatId: wechatId, // 所属微信
        StartTime: 0, // 毫秒，0 则全部
        OnlyNew: false, // 只获取未读的请求
        GetAll: true // 获取所有的加好友请求信息，包括已经添加的
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 接受好友请求 返回  FriendAddNotice
  AcceptFriendAddRequestTask({ rootGetters, commit }, row) {
    const tcwid = rootGetters['tools/currentWeChatId']
    if (!tcwid) return
    const msg = {
      Id: 1075,
      MsgType: 'AcceptFriendAddRequestTask',
      Content: {
        WeChatId: tcwid, // 商家个人微信内部全局唯一识别码
        FriendId: row.FriendId, // 请求的好友微信内部全局唯一识别码
        Operation: 1, // 处理结果 0忽略 1接受 2拒绝
        Remark: row.Remark || '' // 其他备注信息
        // FriendNick: 7, // 好友的微信昵称 没用
        // ReplyMsg: 8,  // 拒绝时的回复消息
        // TaskId: 9, //任务Id
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 添加好友 返回  TaskResultNotice
  AddFriendsTask({ rootGetters, dispatch, commit }, addFriendForm) {
    const content = {
      // IMEI: '', // 该值与WeChatId二选一, IMEI优先
      WeChatId: addFriendForm.WeChatId, // 商家所属微信号
      Phones: addFriendForm.Phones, // 要添加的手机号码，批量
      message: addFriendForm.message, // 发送的验证消息
      Remark: addFriendForm.Remark, // 备注名
      TaskId: addFriendForm.TaskId // 任务Id
    }
    const msg = {
      Id: 1072,
      MsgType: 'AddFriendsTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
    // 任务保存到nedb
    // 如果用不到本地的加好友数据 可以注释下面的diamagnetic
    const doc = {
      userId: rootGetters.userId,
      result: '指令已发出',
      ...content
    }
    dispatch('nedb/AddOrUpdateAddFriends', doc, { root: true })
  },
  // sdk 清理僵尸粉 返回  PostFriendDetectCountNotice
  PostFriendDetectTask({ commit }, clearForm) {
    const content = {
      WeChatId: clearForm.wechatId, // 微信id
      TaskId: generateTaskId().toString(), // 清粉任务ID
      // Message: 3, // 消息内容（当前发空名片，该字段暂时废弃）
      OnlyCheck: !clearForm.delete, // false 检测到立即删除，true 不删除，通知到服务端
      SkipHour: clearForm.time, // 跳过多少小时内有互动的好友（聊天，朋友圈互动,上次已检测等），缺省72小时
      Mode: clearForm.circle ? 6 : 1 // 1不检测朋友圈 6检测朋友圈
    }
    const msg = {
      Id: 1095,
      MsgType: 'PostFriendDetectTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 停止清理僵尸粉 返回 TaskResultNotice
  PostStopFriendDetectTask({ commit }, wechatId) {
    const content = {
      WeChatId: wechatId, // 微信id
      TaskId: generateTaskId().toString() // 清粉任务ID
    }
    const msg = {
      Id: 1096,
      MsgType: 'PostStopFriendDetectTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 查询联系人 返回  FindContactTaskResult
  FindContactTask({ dispatch, commit }, message) {
    const content = {
      WeChatId: message.WeChatId, // 商家所属微信号
      Content: message.Content // 查找的手机号，微信号，QQ号
    }
    const msg = {
      Id: 1227,
      MsgType: 'FindContactTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
    // 查询记录保存到本地数据库
    const doc = {}
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    doc.time = new Date().getTime()
    doc.result = 0
    dispatch('nedb/AddFindContactTask', doc, { root: true })
  },

  // =============================================================================
  // 以下是 friendCircle 页面相关的sdk
  // =============================================================================

  // sdk 获取某个微信的朋友圈 返回 CirclePushNotice
  PullFriendCircleTask({ commit }, pullMsg) {
    // const startTime = Math.floor(new Date().getTime() / 1000)
    const content = {
      WeChatId: pullMsg.wechatId, // 所属微信
      FriendId: pullMsg.friendId, // 获取好友的朋友圈
      Count: pullMsg.count ? pullMsg.count : 10 // 最多条数，缺省20条，传0则全部
      // StartTime: pullMsg.startTime ? pullMsg.startTime : startTime, // UTC秒，小于该时间，用于向下翻页
      // RefTime: 5 // UTC秒，大于该时间，用于向上翻页
    }
    if (pullMsg.startTime) {
      content.StartTime = pullMsg.startTime
    }
    if (pullMsg.refTime) {
      content.refTime = pullMsg.refTime
    }
    const msg = {
      Id: 1201,
      MsgType: 'PullFriendCircleTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 获取指定朋友圈 返回 CirclePushNotice
  TriggerCirclePushTask({ commit }, circle) {
    const content = {
      WeChatId: circle.WeChatId,
      CircleIds: [circle.CircleId]
    }
    const msg = {
      Id: 1081,
      MsgType: 'TriggerCirclePushTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 删除指定朋友圈 返回 TaskResultNotice
  DeleteSNSNewsTask({ dispatch, commit }, circle) {
    const content = {
      WeChatId: circle.WeChatId,
      CircleId: circle.CircleId,
      TaskId: generateTaskId().toString()
    }
    const msg = {
      Id: 1074,
      MsgType: 'DeleteSNSNewsTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
    const doc = {}
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    doc.MsgType = 'DeleteSNSNewsTask'
    dispatch('nedb/AddCircleTask', doc, { root: true })
  },
  // sdk 点赞|取消点赞 返回 TaskResultNotice
  CircleLikeTask({ dispatch, commit }, circle) {
    let cancel = false
    if (circle.Likes) {
      for (const like of circle.Likes) {
        if (like.FriendId === circle.WeChatId) {
          cancel = true
          break
        }
      }
    }
    const content = {
      WeChatId: circle.WeChatId, // 所属微信
      CircleId: circle.CircleId, // 所属朋友圈
      IsCancel: cancel, // 是否取消点赞
      TaskId: generateTaskId().toString()
    }
    const msg = {
      Id: 1203,
      MsgType: 'CircleLikeTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
    const doc = {
      WeChatId: circle.WeChatId, // 所属微信
      CircleId: circle.CircleId, // 所属朋友圈
      IsCancel: cancel, // 是否取消点赞
      TaskId: content.TaskId,
      MsgType: 'CircleLikeTask'
    }
    if (circle.FriendId) {
      doc.FriendId = circle.FriendId
    }
    dispatch('nedb/AddCircleTask', doc, { root: true })
  },
  // sdk 获取朋友圈图片 返回 CircleDetailNotice
  PullCircleDetailTask({ commit }, circle) {
    const msg = {
      Id: 1202,
      MsgType: 'PullCircleDetailTask',
      Content: {
        WeChatId: circle.WeChatId,
        CircleId: circle.CircleId,
        GetBigMap: false // 获取大图
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 删除评论 返回 CircleCommentDeleteTaskResultNotice
  CircleCommentDeleteTask({ dispatch, commit }, circle) {
    const comment = circle.currentComment

    const content = {
      WeChatId: circle.WeChatId,
      CircleId: circle.CircleId,
      CommentId: comment.CommentId,
      PublishTime: comment.PublishTime,
      TaskId: generateTaskId().toString()
    }
    const msg = {
      Id: 1082,
      MsgType: 'CircleCommentDeleteTask',
      Content: content
    }
    commit('SEND_SOCK', msg)

    // 保存到nedb
    const doc = {
      WeChatId: circle.WeChatId,
      CircleId: circle.CircleId,
      CommentId: comment.CommentId,
      PublishTime: comment.PublishTime,
      TaskId: content.TaskId,
      MsgType: 'CircleCommentDeleteTask',
      Comments: circle.Comments
    }
    if (circle.FriendId) {
      doc.FriendId = circle.FriendId
    }
    dispatch('nedb/AddCircleTask', doc, { root: true })
  },
  // sdk 评论 返回 CircleCommentReplyTaskResultNotice
  CircleCommentReplyTask({ dispatch, commit }, circle) {
    const content = {
      WeChatId: circle.WeChatId, // 所属微信
      CircleId: circle.CircleId, // 所属朋友圈
      Content: circle.value, // 回复的内容
      TaskId: generateTaskId().toString(), // 本地的评论表数据id （重发需要传递）
      IsResend: false // 是否是重发 （手机端忽略）
    }
    // 回复好友的评论
    if (circle.currentComment) {
      content.ToWeChatId = circle.currentComment.FromWeChatId // 回复的好友
      content.ReplyCommentId = circle.currentComment.CommentId // 回复的评论id
    }
    const msg = {
      Id: 1084,
      MsgType: 'CircleCommentReplyTask',
      Content: content
    }
    commit('SEND_SOCK', msg)

    // 保存到nedb
    const doc = {
      WeChatId: circle.WeChatId, // 所属微信
      CircleId: circle.CircleId, // 所属朋友圈
      Content: circle.value, // 回复的内容
      TaskId: content.TaskId, // 本地的评论表数据id （重发需要传递）
      IsResend: false, // 是否是重发 （手机端忽略）
      MsgType: 'CircleCommentReplyTask'
    }
    if (circle.FriendId) {
      doc.FriendId = circle.FriendId
    }
    if (circle.currentComment) {
      doc.ToWeChatId = circle.currentComment.FromWeChatId // 回复的好友
      doc.ReplyCommentId = circle.currentComment.CommentId // 回复的评论id
    }
    dispatch('nedb/AddCircleTask', doc, { root: true })
  },
  // sdk 发送朋友圈 返回 PostSNSNewsTaskResultNotice
  PostSNSNewsTask({ dispatch, commit }, circle) {
    const content = {
      WeChatId: circle.WeChatId,
      Content: circle.Content,
      Comment: circle.Comment,
      TaskId: generateTaskId().toString(),
      Visible: circle.Visible,
      SendSlow: false
    }
    if (circle.Attachment) {
      content.Attachment = circle.Attachment
    }
    const msg = {
      Id: 1071,
      AccessToken: '',
      MsgType: 'PostSNSNewsTask',
      Content: content
    }
    commit('SEND_SOCK', msg)

    // 保存到nedb
    const doc = {}
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    doc.MsgType = 'PostSNSNewsTask'
    dispatch('nedb/AddCircleTask', doc, { root: true })
  },

  // =============================================================================
  // 以下是 群发 页面相关的sdk
  // =============================================================================

  // sdk 群发助手 返回 TaskResultNotice
  WeChatGroupSendTask({ dispatch, commit }, message) {
    const content = {
      TaskId: generateTaskId().toString(), // 任务id
      FriendIds: message.FriendIds, // 目标人群id列表
      ContentType: message.ContentType, // 内容类型
      Content: message.Content, // 文字内容 或 图片列表
      WeChatId: message.WeChatId // 微信号id
    }
    const msg = {
      Id: 1076,
      MsgType: 'WeChatGroupSendTask',
      Content: content
    }
    commit('SEND_SOCK', msg)
    // 任务保存到nedb数据库
    const doc = {}
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    doc.MsgType = 'WeChatGroupSendTask'
    dispatch('nedb/AddGroupSendTask2Nedb', doc, { root: true })
  },

  // =============================================================================
  // 以下是 conversation页面相关的sdk
  // =============================================================================

  // sdk 从手机获取历史消息
  // 返回  HistoryMsgPushNotice
  TriggerHistoryMsgPushTask({ commit }, message) {
    const msg = {
      MsgType: 'TriggerHistoryMsgPushTask',
      Content: {
        WeChatId: message.WeChatId,
        FriendId: message.FriendId, // 好友id, 置空表示全部好友
        StartTime: message.StartTime, // 开始同步的消息时间，0表示全部,UTC毫秒
        EndTime: message.EndTime, // 结束同步的消息时间，0表示到当前时间为止
        Flag: message.Flag || 0, // 获取全部时，0：只有好友， 1：只有群聊， 2：所有（好友和群聊）
        Count: message.Count || 500 // 单个会话获取的最多消息数
      }
    }
    commit('SEND_SOCK', msg)
  },

  // sdk 推送指定微信的通讯录
  // 返回 1 FriendPushNotice 好友列表
  // 返回 2 ContactLabelInfoNotice 标签列表
  TriggerFriendPushTask({ rootGetters, commit }) {
    const wechatId = rootGetters['conversation/currentWeChatId']
    const msg = {
      Id: 1080,
      MsgType: 'TriggerFriendPushTask',
      Content: { WeChatId: wechatId }
    }
    console.log('推送指定微信的通讯录', msg)
    commit('SEND_SOCK', msg)
  },
  // sdk 获取指定好友的详情 返回  FriendAddNotice
  // sdk 获取群成员的详情 返回  ChatRoomMembersNotice
  RequestContactsInfoTask({ commit }, message) {
    const msg = {
      Id: 1212,
      MsgType: 'RequestContactsInfoTask',
      Content: {
        WeChatId: message.wechatId,
        Contact: message.friendId
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 获取指定群聊的详情
  // 返回 1 群的信息 ChatRoomAddNotice
  // 返回 2 群成员信息 ChatRoomMembersNotice
  RequestChatRoomInfoTask({ commit }, message) {
    const msg = {
      Id: 1211,
      MsgType: 'RequestChatRoomInfoTask',
      Content: {
        WeChatId: message.wechatId,
        ChatRoomId: message.friendId,
        Flag: 1 // Flag=1:推送成员信息（ChatRoomMembersNotice)
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 推送群聊列表 推送陌生人列表
  // 返回 1 群聊列表 ChatroomPushNotice
  // 返回 2 陌生人列表 ChatRoomMembersNotice
  TriggerChatroomPushTask({ commit }, wechatId) {
    const msg = {
      Id: 1210,
      MsgType: 'TriggerChatroomPushTask',
      Content: {
        WeChatId: wechatId, // 商家所属微信号
        Flag: 1 // Flag = 1:不推送成员信息（ChatRoomMembersNotice)
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 设置消息为已读 返回 无
  TriggerMessageReadTask({ commit }, message) {
    const msg = {
      Id: 1086,
      MsgType: 'TriggerMessageReadTask',
      Content: {
        WeChatId: message.WeChatId,
        FriendId: message.FriendId
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 发送聊天消息 返回  TalkToFriendTaskResultNotice
  TalkToFriendTask({ commit, dispatch, rootState }, message) {
    const sendTime = new Date().getTime()
    const content = {
      WeChatId: message.WeChatId, // 所属微信号
      FriendId: message.FriendId, // 发送给那个好友
      ContentType: message.ContentType, // 发送消息类型 Text Picture Voice Video Link File NameCard WeApp
      Content: base64.encode(message.Content), // 发送消息内容 文本，文件url，链接json，名片wxid
      Remark: message.Remark,
      MsgId: generateTaskId().toString() // 发送给手机端的时候需要赋值，用于TalkToFriendTaskResultNotice中
    }
    const msg = {
      Id: 1070,
      MsgType: 'TalkToFriendTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
    // 消息保存本地nedb chats
    const doc = {}
    // 保存在本地数据库的时候 把@X带上
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    // 如果是引用消息
    if (message.ContentType === 'QuoteMsg') {
      const cc = { ...rootState['conversation']['quoteDetail'] }
      if (cc.quoteType === 'Video') {
        cc.content = '[视频]'
      } else if (cc.quoteType === 'Picture') {
        cc.content = '[图片]'
      }
      const docContent = { title: message.Content, ...cc }
      doc.Content = base64.encode(JSON.stringify(docContent))
    }
    doc.CreateTime = sendTime.toString()
    doc.IsSend = true
    dispatch('nedb/AddChats', doc, { root: true })
  },
  // 转发消息
  ForwardMessageTask({ commit }, message) {
    const content = {
      MsgSrvId: message.MsgSrvId, // 消息Id
      WeChatId: message.WeChatId, //
      FriendIds: message.FriendIds, // 目标人群id列表，用逗号,分隔
      ExtMsg: message.ExtMsg, // 附带消息
      Talker: message.Talker, // 转发消息的会话
      TaskId: generateTaskId().toString() // 任务id
    }
    const msg = {
      Id: 1088,
      MsgType: 'ForwardMessageTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
  },
  // sdk 修改备注信息 返回 TaskResultNotice
  ModifyFriendMemoTask({ commit, dispatch }, content) {
    content.TaskId = generateTaskId().toString()
    const msg = {
      Id: 1101,
      MsgType: 'ModifyFriendMemoTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
    // 操作保存本地nedb memoTask
    dispatch('nedb/AddMemoTask', content, { root: true })
  },
  // sdk 添加或删除标签
  // 新建标签返回  ContactLabelAddNotice
  // 删除标签返回  ContactLabelDelNotice
  // 打标签结果 TaskResultNotice
  ContactLabelTask({ commit, dispatch }, message) {
    const content = {
      WeChatId: message.WeChatId, // string 商家所属微信号
      LabelId: message.LabelId || 0, // int32 标签id，0则新建标签（如存在同名标签则会失败）
      taskId: generateTaskId().toString() // int64
      // LabelName: message.LabelName || '', //string 标签名 id!=0 会设置为该标签名
      // AddList: message.AddList || '', //string 新增的wxid，用,分隔
      // DelList: message.DelList || '', //string 删除的wxid，用,分隔
    }
    if (message.LabelName) {
      content.LabelName = message.LabelName
    }
    if (message.AddList) {
      content.AddList = message.AddList
    }
    if (message.DelList) {
      content.DelList = message.DelList
    }
    const msg = {
      Id: 1224,
      MsgType: 'ContactLabelTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
    const doc = {}
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    doc.TaskId = content.taskId
    // 操作保存本地nedb memoTask
    dispatch('nedb/AddMemoTask', doc, { root: true })
  },
  // sdk 群管理 返回 TaskResultNotice
  ChatRoomActionTask({ commit, dispatch }, message) {
    const content = {
      WeChatId: message.WeChatId, // string 商家所属微信号
      // ChatRoomId: message.ChatRoomId, //string 群聊id
      Action: message.Action, // EnumChatRoomAction 指令
      // Content: 4, //string 指令内容
      // IntValue: 5, //int32
      taskId: generateTaskId().toString() // int64
    }
    if (message.ChatRoomId) {
      content.ChatRoomId = message.ChatRoomId
    }
    if (typeof message.IntValue === 'number') {
      content.IntValue = message.IntValue
    }
    if (message.Content) {
      content.Content = message.Content
    }
    const msg = {
      Id: 1213,
      MsgType: 'ChatRoomActionTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)

    const doc = {}
    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        doc[key] = content[key]
      }
    }
    doc.TaskId = content.taskId
    // 操作保存本地nedb chatRoomTask
    dispatch('nedb/AddChatRoomTask', doc, { root: true })
  },
  // sdk 获取群二维码 返回 PullChatRoomQrCodeTaskResultNotice
  PullChatRoomQrCodeTask({ commit }, message) {
    const msg = {
      Id: 1090,
      MsgType: 'PullChatRoomQrCodeTask',
      Content: {
        WeChatId: message.WeChatId, // 商家所属微信号
        ChatRoomId: message.UserName, // 群聊id
        taskId: generateTaskId().toString()
      }
    }
    commit('SEND_SOCK', msg)
  },
  // sdk 添加群成员到通讯录 返回 TaskResultNotice
  AddFriendInChatRoomTask({ commit, dispatch }, message) {
    const msg = {
      Id: 1214,
      MsgType: 'AddFriendInChatRoomTask',
      Content: {
        WeChatId: message.WeChatId, // 商家个人微信内部全局唯一识别码
        ChatroomId: message.ChatroomId, // 所在的群聊id
        FriendId: message.FriendId, // 请求加好友微信内部全局唯一识别码
        Message: message.Message, // 招呼语
        Remark: message.Remark, // 备注信息
        TaskId: generateTaskId().toString() // 任务Id
      }
    }
    commit('SEND_SOCK', msg)
    const doc = msg.Content
    // 操作保存本地nedb chatRoomTask
    dispatch('nedb/AddChatRoomTask', doc, { root: true })
  },
  // sdk 发红包 返回 TaskResultNotice
  SendLuckyMoneyTask({ commit, dispatch }, message) {
    const sendTime = new Date().getTime()
    const taskId = sendTime * 10000 + Math.random() * 10000
    const content = {
      WeChatId: message.WeChatId, // 微信唯一Id
      FriendId: message.FriendId, // 聊天好友微信唯一id
      Money: message.Money, // 钱数，单位：分，最多20000
      Number: message.Number, // 红包个数，缺省1个
      Passwd: message.Passwd, // 密码，6位，纯数字
      Wish: message.Wish, // 祝福语
      TaskId: taskId // 任务id
    }
    const msg = {
      Id: 1217,
      MsgType: 'SendLuckyMoneyTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
    // 消息保存本地nedb chats
    const doc = {}
    // 按收到的红包格式创建一个本地消息
    const redContent = {
      Title: message.Wish,
      Key: '',
      InvalidTime: ''
    }
    // 发红包的自定义的字段
    redContent.Money = message.Money
    doc.Content = base64.encode(JSON.stringify(redContent))
    doc.ContentType = 'LuckyMoney'
    doc.FriendId = message.FriendId
    doc.WeChatId = message.WeChatId
    doc.MsgId = taskId.toString()
    // doc.msgSvrId = friendId
    doc.CreateTime = sendTime.toString()
    doc.IsSend = true
    dispatch('nedb/AddChats', doc, { root: true })

    // 插入luncMoney, 好友领红包的时候用到
    // doc.opened = 0
    // doc.InvalidTime = ''
    // doc.Key = ''
    // doc.Title = content.Wish
    // doc.SendId = ''
    //     this.$nedb.luckMoney.insert(
    //         doc,
    //         (err, newDoc) => { // Callback is optional
    //             if (err) return
    //         })
  },
  // sdk 转账 返回 TaskResultNotice
  RemittanceTask({ commit, dispatch }, message) {
    const sendTime = new Date().getTime()
    const content = {
      WeChatId: message.WeChatId, // 微信唯一Id
      FriendId: message.FriendId, // 聊天好友微信唯一id
      Money: message.Money, // 钱数，单位：分
      Passwd: message.Passwd, // 密码，6位，纯数字
      Memo: message.Memo, // 留言
      TaskId: generateTaskId().toString() // 任务id
    }
    const msg = {
      Id: 1260,
      MsgType: 'RemittanceTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)

    // 消息保存本地nedb chats
    const doc = {}
    // 按收到的红包格式创建一个本地消息
    const redContent = {
      Title: message.Memo,
      Feedesc: '￥' + message.Money / 100,
      Key: '',
      PaySubType: 1,
      InvalidTime: ''
    }
    // 转账的自定义的字段
    doc.Content = base64.encode(JSON.stringify(redContent))
    doc.ContentType = 'MoneyTrans'
    doc.FriendId = message.FriendId
    doc.WeChatId = message.WeChatId
    doc.MsgId = content.TaskId
    // doc.msgSvrId = friendId
    doc.CreateTime = sendTime.toString()
    doc.IsSend = true
    dispatch('nedb/AddChats', doc, { root: true })
  },
  // sdk 获取消息详情
  // 如果成功返回  RequestTalkDetailTaskResultNotice
  // 如果失败返回  TaskResultNotice
  RequestTalkDetailTask({ commit }, message) {
    const content = {
      MsgId: message.MsgId, // 全局消息id
      WeChatId: message.WeChatId, // 微信唯一Id
      FriendId: message.FriendId, // 聊天好友微信唯一id
      MsgSvrId: message.msgSvrId ? message.msgSvrId : '', // 微信设备上消息唯一id(FriendTalkNotice中上传)
      Md5: message.Content && message.Content.Md5 ? message.Content.Md5 : '', // 图片或视频md5(FriendTalkNotice中上传)
      GetOriginal: true // 获取原图（获取接收到的图片消息的原图时置true，其他false）
    }
    const msg = {
      Id: 1078,
      MsgType: 'RequestTalkDetailTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
  },
  // sdk 删除好友 返回 FriendDelNotice
  DeleteFriendTask({ commit }, message) {
    const content = {
      WeChatId: message.WeChatId, // 商家所属微信号
      FriendId: message.FriendId, // 要删除的微信号
      TaskId: generateTaskId().toString() // 任务Id
    }
    const msg = {
      Id: 1216,
      MsgType: 'DeleteFriendTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
  },
  // sdk 消息撤回 返回 TaskResultNotice
  RevokeMessageTask({ commit }, chat) {
    const content = {
      MsgId: chat.msgSvrId, // 这里传MsgSvrId，传long类型，js中传字符串
      WeChatId: chat.WeChatId,
      FriendId: chat.FriendId,
      TaskId: chat.msgSvrId
    }
    const msg = {
      Id: 1087,
      MsgType: 'RevokeMessageTask',
      Content: content
    }
    // 发送消息
    commit('SEND_SOCK', msg)
  },
  // sdk 同意加入群聊 返回 TaskResultNotice
  AgreeJoinChatRoomTask({ commit }, message) {
    const msg = {
      Id: 1229,
      MsgType: 'AgreeJoinChatRoomTask',
      Content: {
        WeChatId: message.WeChatId, // 商家个人微信内部全局唯一识别码
        Talker: message.Talker, // 邀请者
        MsgSvrId: message.MsgSvrId, // 邀请消息的msgSvrId
        MsgContent: message.MsgContent, // 回传邀请信息的内容（json）
        taskId: generateTaskId().toString()
      }
    }
    // 发送消息
    commit('SEND_SOCK', msg)
  },
  // sdk 打开红包或转账 返回 TakeMoneyTaskResultNotice
  TakeLuckyMoneyTask({ commit, dispatch }, message) {
    const msg = {
      Id: 1200,
      MsgType: 'TakeLuckyMoneyTask',
      Content: {
        WeChatId: message.WeChatId, // 微信唯一Id
        FriendId: message.FriendId, // 聊天好友微信唯一id
        MsgSvrId: message.MsgSvrId, // 全局消息id
        MsgKey: message.MsgKey, // 消息内容中的Key
        TaskId: generateTaskId().toString() //
      }
    }
    // 发送消息
    commit('SEND_SOCK', msg)
    // 添加到数据库
    const doc = {}
    for (const key in msg.Content) {
      if (msg.Content.hasOwnProperty(key)) {
        doc[key] = msg.Content[key]
      }
    }
    dispatch('nedb/InsertLuckMoney', doc, { root: true })
  },
  // sdk 查询红包 返回 QueryHbDetailTaskResultNotice
  QueryHbDetailTask({ commit }, message) {
    const msg = {
      Id: 1265,
      MsgType: 'QueryHbDetailTask',
      Content: {
        WeChatId: message.WeChatId, // 所属微信号
        HbUrl: message.HbUrl // 红包key
      }
    }
    // 发送消息
    commit('SEND_SOCK', msg)
    // 添加到数据库
    // let doc = {}
    // for (const key in msg.Content) {
    //     if (msg.Content.hasOwnProperty(key)) {
    //         doc[key] = msg.Content[key]
    //     }
    // }
    // dispatch('nedb/InsertLuckMoney', doc, { root: true })
  },
  // sdk 推送公共号列表 返回 BizContactPushNotice
  TriggerBizContactPushTask({ commit, rootGetters }) {
    const msg = {
      Id: 1235,
      MsgType: 'TriggerBizContactPushTask',
      Content: {
        WeChatId: rootGetters['conversation/currentWeChatId'] // 微信唯一Id
      }
    }
    commit('SEND_SOCK', msg)
  }
}

// mutations
const mutations = {
  // 发送消息
  SEND_SOCK(state, agentData) {
    sendSock(agentData)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
