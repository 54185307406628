import Vue from 'vue'

// state
const state = {
  indexedDB: window.indexedDB || window.webkitindexedDB || window.msIndexedDB || window.mozIndexedDB,
  qrCodes: {}, // 微信二维码
  newFriends: {} // 新的好友

}

// getters
const getters = {
  indexedDB: state => state.indexedDB,
  qrCodes: state => state.qrCodes,
  newFriends: state => state.newFriends
}

// actions
const actions = {
  // 从indexDB获取qrCode数据
  SetQrCodes({ state, commit }) {
    const indexedDB = state.indexedDB
    const openRequest = indexedDB.open('qrCode', 1)
    openRequest.onupgradeneeded = function(e) {
      const db = e.target.result
      if (!db.objectStoreNames.contains('qrCode')) {
        db.createObjectStore('qrCode', { keyPath: 'WeChatId' })
      }
    }
    openRequest.onsuccess = function(e) {
      const db = e.target.result
      const transaction = db.transaction(['qrCode'], 'readonly')
      const objectStore = transaction.objectStore('qrCode')
      objectStore.openCursor().onsuccess = function(event) {
        const cursor = event.target.result
        if (cursor) {
          commit('SET_QR_CODES', cursor)
          cursor.continue()
        }
      }
    }
    openRequest.onerror = function(e) {
    }
    return {}
  },
  // 更新或添加indexDB中的qrCode数据
  UpdateOrAddQrCode({ state, dispatch }, qrcode) {
    const indexedDB = state.indexedDB
    const openRequest = indexedDB.open('qrCode', 1)
    // 打开数据库 如果没有就创建
    openRequest.onupgradeneeded = function(e) {
      const db = e.target.result
      if (!db.objectStoreNames.contains('qrCode')) {
        db.createObjectStore('qrCode', { keyPath: 'WeChatId' })
      }
    }
    // 打开数据库成功
    openRequest.onsuccess = function(e) {
      const db = e.target.result
      const transaction = db.transaction('qrCode', 'readwrite')
      const store = transaction.objectStore('qrCode')
      // 先查找 如果能查到 就更新,如果查不到 就添加
      const request = store.get(qrcode.WeChatId)
      request.onsuccess = function(e) {
        const stockTable = e.target.result
        if (stockTable) {
          // 有数据, 更新数据
          stockTable.QrCodeUrl = qrcode.QrCodeUrl
          stockTable.created = qrcode.created
          const updataRequest = store.put(stockTable)
          updataRequest.onsuccess = function(event) {
            // 数据更新成功
            dispatch('SetQrCodes')
          }
          updataRequest.onerror = function(event) {
            // 数据更新失败
          }
        } else {
          // 未获得数据记录, 添加数据
          const addRequest = store.add(qrcode)
          addRequest.onsuccess = function(event) {
            // 数据更新成功
            dispatch('SetQrCodes')
          }
          addRequest.onerror = function(event) {
            // 数据更新失败
          }
        }
      }
      request.onerror = function(event) {
        // 事务失败
      }
    }
    // 打开数据库报错
    openRequest.onerror = function(e) { }
  },
  // 从indexDB获取newFriends数据
  SetNewFriends({ state, commit }) {
    const indexedDB = state.indexedDB
    const openRequest = indexedDB.open('newFriends', 1)

    openRequest.onupgradeneeded = function(e) {
      // 创建NewFriends数据库
      const db = e.target.result
      if (!db.objectStoreNames.contains('newFriends')) {
        db.createObjectStore('newFriends', { keyPath: 'WeChatId' })
      }
    }

    openRequest.onsuccess = function(e) {
      const db = e.target.result
      const transaction = db.transaction(['newFriends'], 'readonly')
      const objectStore = transaction.objectStore('newFriends')
      objectStore.openCursor().onsuccess = function(event) {
        const cursor = event.target.result
        if (cursor) {
          commit('SET_NEW_FRIENDS', cursor)
          cursor.continue()
        }
      }
    }
    openRequest.onerror = function(e) {
    }
    return {}
  },
  // 更新或添加indexDB中的qrCode数据
  UpdateOrAddNewFriends({ state, dispatch }, message) {
    // Requests
    const indexedDB = state.indexedDB
    const openRequest = indexedDB.open('newFriends', 1)
    // 打开数据库 如果没有就创建
    openRequest.onupgradeneeded = function(e) {
      const db = e.target.result
      if (!db.objectStoreNames.contains('newFriends')) {
        db.createObjectStore('newFriends', { keyPath: 'WeChatId' })
      }
    }
    // 打开数据库成功
    openRequest.onsuccess = function(e) {
      const db = e.target.result
      const transaction = db.transaction('newFriends', 'readwrite')
      const store = transaction.objectStore('newFriends')
      // 先查找 如果能查到 就更新,如果查不到 就添加
      const request = store.get(message.WeChatId)
      request.onsuccess = function(e) {
        const stockTable = e.target.result
        if (stockTable) {
          // 有数据, 更新数据
          stockTable.Requests = message.Requests
          stockTable.created = message.created
          const updataRequest = store.put(stockTable)

          updataRequest.onsuccess = function(event) {
            // 数据更新成功
            dispatch('SetNewFriends')
          }
          updataRequest.onerror = function(event) {
          }
        } else {
          const addRequest = store.add(message)
          addRequest.onsuccess = function(event) {
            dispatch('SetNewFriends')
          }
          addRequest.onerror = function(event) {
          }
        }
      }
      request.onerror = function(event) {
      }
    }
    // 打开数据库报错
    openRequest.onerror = function(e) {
    }
  }
}

// mutations
const mutations = {
  // 设置qrCodes
  SET_QR_CODES(state, cursor) {
    Vue.set(state.qrCodes, cursor.key, cursor.value)
  },
  // 设置newFriends
  SET_NEW_FRIENDS(state, cursor) {
    Vue.set(state.newFriends, cursor.key, cursor.value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
