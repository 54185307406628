import Vue from "vue";
import base64 from "@/utils/base64";
import { TriggerConversationPushTask } from "@/api/webSocketApi";

/**
 * Json数组去重
 * @param {Array} arr
 */
function unique(arr) {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i].UserName === arr[j].UserName) {
        // 第一个等同于第二个，splice方法删除第二个
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
}

/**
 * 获取消息的内容
 * @param {Object} talk 消息
 */
function getContent(talk) {
  let digest = "";
  switch (talk.ContentType) {
    case "Text":
      digest = base64.decode(talk.Content);
      if (
        talk.FriendId.indexOf("@chatroom") > 0 ||
        talk.FriendId.indexOf("@im.chatroom") > 0
      ) {
        digest = unescape(
          escape(digest.replace(/.*?:/, "")).replace("%0A", "%20")
        ).replace(" ", "");
      }
      break;
    case "Picture":
    case 2:
      digest = "[图片]";
      break;
    // 语音 3
    case "Voice":
    case 3:
      digest = "[语音]";
      break;
    // 视频 4
    case "Video":
    case 4:
      digest = "[视频]";
      break;
    // 系统消息 5
    case "System":
    case 5:
      try {
        digest = base64.decode(talk.Content);
      } catch (error) {
        digest = "[系统消息]";
      }
      break;
    // 链接消息 6
    case "Link":
    case 6:
      digest = "[链接]";
      break;
    // 扩展的链接消息（小程序分享等），内容为xml格式，暂未使用 7
    case "LinkExt":
    case 7:
      digest = "[扩展的链接消息]";
      break;
    // 文件 8
    case "File":
    case 8:
      digest = "[文件]";
      break;
    // 名片 9
    case "NameCard":
    case 9:
      digest = "[名片]";
      break;
    // 位置信息 10
    case "Location":
    case 10:
      digest = "[位置消息]";
      break;
    // 红包 11
    case "LuckyMoney":
    case 11:
      digest = "[链接]";
      break;
    // 转账 12
    case "MoneyTrans":
    case 12:
      digest = "[红包]";
      break;
    // 小程序 13
    case "WeApp":
    case 13:
      digest = "[小程序]";
      break;
    // Emoji 14
    case "Emoji":
    case 14:
      digest = "[Emoji]";
      break;
    // 群管理消息 15
    case "RoomManage":
    case 15:
      digest = "[群管理消息]";
      break;
    // 红包系统消息 16
    case "Sys_LuckyMoney":
    case 16:
      digest = "[红包系统消息]";
      break;
    // 群系统消息 17
    case "RoomSystem":
    case 17:
      digest = "[群系统消息]";
      break;
    // 拍一拍消息
    case "PaiYiPai":
    case 26:
      digest = "[群系统消息]";
      try {
        digest = base64.decode(talk.Content);
      } catch (error) {
        digest = "[拍一拍]";
      }
      break;
    // 不支持的消息
    default:
      digest = "[不支持的消息]";
      break;
  }
  return digest;
}

// initial state
const state = {
  // ============== AsidePage用到的变量 ==============
  wechats: [], // 当前用户绑定的微信
  currentWechat: {}, // 会话页面选中的微信
  conversations: {}, // 每个微信的会话

  // ============== ConversationFriends用到的变量 ====
  // currentTab: 'convs', // ConversationFriends页面的tab
  lastChatPages: {}, // ConversationFriends页面的tab页面回显
  addressListPages: {}, // ConversationFriends页面的tab页面回显
  chatRoomPages: {}, // ConversationFriends页面的tab页面回显
  currentFriends: {}, // 会话页面每个微信选中的好友
  refreshButton: true, // 刷新按钮可用

  // ============== ConversationChats用到的变量 =======
  content: "", // 当前要发送的内容， 默认为空
  remark: "", // 要@的群成员的id
  quoteMsg: "", // 引用消息的msgSvrId
  quoteDetail: {}, // 引用的详情
  contentType: "Text", // 当前要发送的消息类型，默认为文本
  hbDetailVisible: false, // 显示红包详情
  hbDetail: {
    WeChatId: "", // 所属微信号
    Success: false,
    ErrMsg: "",
    HbUrl: "", //
    TotalNum: null, // 红包总个数
    TotalAmount: null, // 红包总金额
    RecNum: null, // 已收红包个数
    Records: [], // 已收红包个数
    RecAmount: null, // 已收红包金额
  }, // 红包详情

  // ============== ConversationTools用到的变量 =======
  publicReply: [], // 公共话术
  publicTags: [], // 公共标签
  ghList: [], // 公众号列表
  ghMap: {}, // 公众号列表
};

// getters
const getters = {
  // ============== AsidePage用到的变量 ==============
  wechats: (state) => {
    const onlineWechats = []; // 在线的微信
    const offlineWechats = []; // 不在线的微信
    state.wechats.forEach((element) => {
      if (element.IsOnline) {
        onlineWechats.push(element);
      }
    });
    state.wechats.forEach((element) => {
      if (!element.IsOnline) {
        offlineWechats.push(element);
      }
    });
    return onlineWechats.concat(offlineWechats);
  }, // 当前账号绑定的微信列表 排序后的
  wechatsMap: (state) => {
    const wm = {};
    state.wechats.forEach((e) => {
      wm[e.WeChatId] = e;
    });
    return wm;
  }, // 当前账号绑定的微信Map(id-info)
  wechatsNameMap: (state) => {
    const wm = {};
    state.wechats.forEach((e) => {
      wm[e.WeChatId] = e.WeChatNick;
    });
    return wm;
  }, // 当前账号绑定的微信昵称Map(id-nick)
  wechatsOnline: (state) => {
    return state.wechats.filter((element) => {
      return element.IsOnline;
    });
  }, // 当前账号绑定的并且在线的微信列表
  currentConvs: (state) => {
    if (state.conversations[state.currentWechat.WeChatId]) {
      const [...convsList] = state.conversations[state.currentWechat.WeChatId];
      // 按时间降序
      const pll = convsList.sort((a, b) => {
        return b.UpdateTime - a.UpdateTime;
      });
      return pll;
    }
    return [];
  }, // 当前微信的会话降序排列
  messagesNotRead(state) {
    const mnr = {};
    const convs = state.conversations;
    for (const key in convs) {
      if (Object.hasOwnProperty.call(convs, key)) {
        mnr[key] = 0;
        for (const conv of convs[key]) {
          // 免打扰的群不统计
          if (!conv.IsSilent && conv.UnreadCnt) {
            mnr[key] = mnr[key] + conv.UnreadCnt;
          }
        }
      }
    }
    return mnr;
  }, // 每个设备的未读消息数统计
  currentWechat: (state) => {
    state.currentWechat;
  }, // 当前微信
  currentWeChatId: (state) => {
    return  state.currentWechat.WeChatId==null?state.currentWechat.wechatid:"";
  }, // 当前微信id
  currentFriend: (state, getters) => {
    if (state.currentFriends[getters.currentWeChatId]) {
      const cf = { ...state.currentFriends[getters.currentWeChatId] };
      if (cf.LabelIds && typeof cf.LabelIds === "string") {
        cf.LabelIds = cf.LabelIds.split(",");
      }
      return cf;
    } else {
      return {};
    }
  }, // 当前微信选中的好友
  currentFriendId: (state, getters) => {
    return (
      getters.currentFriend.FriendId || getters.currentFriend.UserName || ""
    );
  }, // 当前微信选中的好友的id

  // ================================ 河蟹的分割线 ================================
  // ============== ConversationFriends用到的变量 ====
  // currentTab: (state) => state.currentTab,
  lastChatPages: (state) => state.lastChatPages,
  addressListPages: (state) => state.addressListPages,
  chatRoomPages: (state) => state.chatRoomPages,
  refreshButton: (state) => state.refreshButton,
  // ============== ConversationChats用到的变量 =======
  content: (state) => state.content,
  remark: (state) => state.remark,
  contentType: (state) => state.contentType,
  hbDetailVisible: (state) => state.hbDetailVisible,
  hbDetail: (state) => state.hbDetail,
  // ============== ConversationTools用到的变量 =======
  publicReply: (state) => state.publicReply,
  publicTags: (state) => state.publicTags,
  ghMap: (state) => state.ghMap,
  ghList: (state) => state.ghList,
};

// actions
const actions = {
  // 更新会话列表
  UpdateConversations: ({ state, commit }, talk) => {
    const wechatId = talk.WeChatId;
    const oldConvs = state.conversations;
    const digest = getContent(talk);
    // 如果当前有数据
    if (oldConvs[wechatId]) {
      const targetConvs = JSON.parse(JSON.stringify(oldConvs[wechatId]));
      let flag = true; // 目前有该好友的会话信息
      for (const conv of targetConvs) {
        if (conv.UserName === talk.FriendId) {
          // 更新时间
          conv.UpdateTime = talk.CreateTime;
          // 更新消息内容
          conv.Digest = digest;
          // 群消息 发送者
          if (
            talk.FriendId.indexOf("@chatroom") > 0 ||
            talk.FriendId.indexOf("@im.chatroom") > 0
          ) {
            conv.DigestUser = base64.decode(talk.Content).split(":")[0];
          }
          // 设置消息总数
          conv.MsgCnt = conv.MsgCnt ? conv.MsgCnt + 1 : 1;

          // 设置未读消息
          if (talk.ContentType !== "System" && talk.ContentType !== 5) {
            conv.UnreadCnt = conv.UnreadCnt ? conv.UnreadCnt + 1 : 1;
          }

          // 是否发送
          if (talk.IsSend) {
            conv.IsSend = true;
            conv.UnreadCnt = 0;
          }
          flag = false;
          break;
        }
      }
      if (flag) {
        TriggerConversationPushTask(wechatId);
      } else {
        commit("UPDATE_CONVERSATIONS", {
          wechatId: wechatId,
          targetConvs: targetConvs,
        });
      }
    } else {
      // 创建一个新的会话太麻烦 还是刷新会话列表吧
      TriggerConversationPushTask(wechatId);
    }
  },
  // 设置会话已读
  SetConvReaded: ({ state, getters, commit }, currentConv) => {
    const wechatId = getters.currentWeChatId;
    const oldConvs = state.conversations;
    if (wechatId && oldConvs[wechatId]) {
      const targetConvs = JSON.parse(JSON.stringify(oldConvs[wechatId]));
      for (const conv of targetConvs) {
        if (conv.UserName === currentConv.UserName) {
          if (conv.UnreadCnt) {
            conv.UnreadCnt = 0;
          }
          break;
        }
      }
      commit("UPDATE_CONVERSATIONS", {
        wechatId: wechatId,
        targetConvs: targetConvs,
      });
    }
  },
  // ==================================================================
  // 更新currentFriends
  UpdateCurrentFriends({ commit, getters }, friend) {
    // 当前的微信
    const currentWeChatId = getters.currentWeChatId;
    // 当前的好友
    const cf = getters.currentFriend;
    if (
      friend.WeChatId === currentWeChatId &&
      cf &&
      friend.FriendId === cf.FriendId
    ) {
      const fmsg = {
        wechatId: friend.WeChatId,
        friend: friend,
      };
      commit("SET_CURRENT_FRIENDS", fmsg);
    }
  },
  // 更新currentFriends
  UpdateCurrentFriendsByRoom({ commit, getters }, room) {
    // 当前的微信
    const currentWeChatId = getters.currentWeChatId;
    // 当前的好友
    const cf = getters.currentFriend;
    if (
      room.WeChatId === currentWeChatId &&
      cf &&
      room.UserName === cf.UserName
    ) {
      const fmsg = {
        wechatId: room.WeChatId,
        friend: room,
      };
      commit("SET_CURRENT_FRIENDS", fmsg);
    }
  },
};

// mutations
const mutations = {
  // 重置数据
  RESET_DATA(state) {
    state.wechats = []; // 当前用户绑定的微信
    state.currentWechat = {}; // 会话页面选中的微信
    // state.currentTab = 'LastChat' // ConversationFriends页面的tab
    state.lastChatPages = {}; // ConversationFriends页面的tab页面回显
    state.addressListPages = {}; // ConversationFriends页面的tab页面回显
    state.chatRoomPages = {}; // ConversationFriends页面的tab页面回显
    state.currentFriends = {}; // 会话页面每个微信选中的好友
    state.refreshButton = false; // 刷新按钮不可用
    state.content = ""; // 当前要发送的内容， 默认为空
    state.remark = ""; // 要@的群成员的id
    state.quoteMsg = ""; // 引用消息的msgSvrId
    state.quoteDetail = {}; // 引用的详情
    state.contentType = "Text"; // 当前要发送的消息类型，默认为文本
    state.hbDetailVisible = false; // 显示红包详情
    state.publicReply = []; // 公共话术
    state.publicTags = []; // 公共标签
    state.ghList = []; // 公众号列表
    state.ghMap = {}; // 公众号列表
    state.hbDetail = {
      WeChatId: "", // 所属微信号
      Success: false,
      ErrMsg: "",
      HbUrl: "", //
      TotalNum: null, // 红包总个数
      TotalAmount: null, // 红包总金额
      RecNum: null, // 已收红包个数
      Records: [], // 已收红包个数
      RecAmount: null, // 已收红包金额
    }; // 红包详情
  },
  // ============== AsidePage用到的变量 ==============
  SET_WECHATS: (state, wechats) => {
    state.wechats = wechats;
  },
  // 设置当前选中的微信
  SET_CURRENT_WECHAT: (state, wechat) => {
    state.currentWechat = wechat;
  },
  // 设置每个微信的会话
  SET_CONVERSATIONS: (state, convsData) => {
    const convWechatId = convsData.WeChatId;
    const newConvs = convsData.Convers || [];
    if (convWechatId && newConvs) {
      if (state.conversations[convWechatId]) {
        Vue.set(
          state.conversations,
          convWechatId,
          unique(state.conversations[convWechatId].concat(newConvs))
        );
      } else {
        Vue.set(state.conversations, convWechatId, newConvs);
      }
    }
  },
  // 删除指定微信的conversations
  DELETE_CONVERSATIONS: (state, wechatId) => {
    Vue.set(state.conversations, wechatId, []);
  },
  // 更新会话列表
  UPDATE_CONVERSATIONS: (state, newData) => {
    Vue.set(state.conversations, newData.wechatId, newData.targetConvs);
  },
  // 微信下线
  WECHAT_OFF_LINE: (state, wechatId) => {
    for (const wechat of state.wechats) {
      if (wechat.WeChatId === wechatId) {
        wechat.IsOnline = false;
        break;
      }
    }
  },
  // 微信上线
  WECHAT_ON_LINE: (state, wechatInfo) => {
    let i = 0;
    let flag = false;
    for (const wechat of state.wechats) {
      if (wechat.WeChatId === wechatInfo.WeChatId) {
        flag = true;
        break;
      }
      i++;
    }
    if (flag) {
      state.wechats.splice(i, 1, wechatInfo);
    } else {
      state.wechats.push(wechatInfo);
    }
  },
  // ============== ConversationFriends用到的变量 ====
  SET_REFRESH_BUTTON: (state, flag) => {
    state.refreshButton = flag;
  },
  // // 设置当前的nav
  // SET_CURRENT_TAB: (state, tab) => {
  //   state.currentTab = tab
  // },
  // 设置当前会话的当前页
  SET_LAST_CHAT_PAGES: (state, msg) => {
    Vue.set(state.lastChatPages, msg.wechatId, msg.page);
  },
  // 设置通讯录的当前页
  SET_ADDRESS_LIST_PAGES: (state, msg) => {
    Vue.set(state.addressListPages, msg.wechatId, msg.page);
  },
  // 设置群聊的当前页
  SET_CHAT_ROOM_PAGES: (state, msg) => {
    Vue.set(state.chatRoomPages, msg.wechatId, msg.page);
  },
  // 设置当前微信 所选的好友
  SET_CURRENT_FRIENDS: (state, msg) => {
    Vue.set(state.currentFriends, msg.wechatId, msg.friend);
  },
  // ============== ConversationChats用到的变量 =======
  // 设置消息内容
  SET_CONTENT: (state, content) => {
    state.content = content;
  },
  SET_REMARK: (state, remark) => {
    state.remark = remark;
  },
  SET_QUOTE_MSG: (state, qm) => {
    state.quoteMsg = qm;
  },
  SET_QUOTE_DETAIL: (state, quoteDetailInfo) => {
    state.quoteDetail = quoteDetailInfo;
  },
  // 设置消息类型
  SET_CONTENT_TYPE: (state, type) => {
    state.contentType = type;
  },
  // 设置显示红包详情
  SET_HB_DETAIL_VISIBLE: (state, visible) => {
    state.hbDetailVisible = visible;
  },
  // 设置显示红包详情
  SET_HB_DETAIL: (state, hbdetail) => {
    for (const key in hbdetail) {
      if (hbdetail.hasOwnProperty(key)) {
        Vue.set(state.hbDetail, key, hbdetail[key]);
      }
    }
  },
  // ============== ConversationTools用到的变量 ====
  // 设置公共话术
  SET_PUBLIC_REPLY: (state, publicReply) => {
    state.publicReply = publicReply;
  },
  // 设置公共标签
  SET_PUBLIC_TAGS: (state, publicTags) => {
    state.publicTags = publicTags;
  },
  // 设置公共号列表
  SET_GH_LIST: (state, ghList) => {
    state.ghList = ghList.Contacts;
  },
  SET_GH_MAP: (state, ghMap) => {
    Vue.set(state.ghMap, ghMap.WeChatId, ghMap.Contacts);
  },
  // 更新会话的置顶数据
  UPDATE_IS_TOP(state, message) {
    const wechatId = message.WeChatId;
    const oldConvs = state.conversations[wechatId];
    // 如果当前有数据
    if (oldConvs) {
      for (let index = 0; index < oldConvs.length; index++) {
        const element = oldConvs[index];
        if (element.UserName === message.ChatRoomId) {
          element.IsTop = message.IntValue === 1;
          break;
        }
      }
      Vue.set(state.conversations, message.WeChatId, oldConvs);
    }
  },
  // 更新会话的免打扰数据
  UPDATE_IS_SILENT(state, message) {
    const wechatId = message.WeChatId;
    const oldConvs = state.conversations[wechatId];
    // 如果当前有数据
    if (oldConvs) {
      for (let index = 0; index < oldConvs.length; index++) {
        const element = oldConvs[index];
        if (element.UserName === message.ChatRoomId) {
          element.IsSilent = message.IntValue === 0;
          break;
        }
      }
      Vue.set(state.conversations, message.WeChatId, oldConvs);
    }
  },
  // 删除已经退出群聊的群聊会话
  DELETE_CONV(state, message) {
    const wechatId = message.WeChatId;
    const oldConvs = state.conversations[wechatId];
    // 如果当前有数据
    if (oldConvs) {
      for (let index = 0; index < oldConvs.length; index++) {
        const element = oldConvs[index];
        if (element.UserName === message.RoomId) {
          console.log(element);
          oldConvs.splice(index, 1);
          break;
        }
      }
      Vue.set(state.conversations, message.WeChatId, oldConvs);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
