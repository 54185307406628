import jwtDefaultConfig from './jwtDefaultConfig'

const qs = require('qs')

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        console.log("accessToken" + accessToken)
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          config.headers.token = accessToken
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)

              //  this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, qs.stringify(...args))
  }
  //超管查询所有设备信息
  queryDevicesAll(...args) {
    return this.axiosIns.post(this.jwtConfig.queryDevicesAll, qs.stringify(...args))
  }
  //管理员查询名下的所有设备信息
  queryDevices(...args) {
    return this.axiosIns.post(this.jwtConfig.queryDevices, qs.stringify(...args))
  }
  //删除名下的设备
  deleteDevices(...args) {
    return this.axiosIns.post(this.jwtConfig.deleteDevices, qs.stringify(...args))
  }
  //根据ID查询微信号信息
  queryAccountsById(...args) {
    return this.axiosIns.post(this.jwtConfig.findDeviceStateInfoByid, qs.stringify(...args))
  }
  //根据客户ID查询客户公司部门
  queryByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryByCid, qs.stringify(...args))
  }

  //根据客户id跟部门id查找客服列表
  getAllAccountInfoByTypeCidDid(...args) {
    return this.axiosIns.post(this.jwtConfig.getAllAccountInfoByTypeCidDid, qs.stringify(...args))
  }
  //更新设备信息
  updateDevice(...args) {
    return this.axiosIns.post(this.jwtConfig.updateDevice, qs.stringify(...args))
  }
  //新增设备信息
  addDevice(...args) {
    return this.axiosIns.post(this.jwtConfig.addDevice, qs.stringify(...args))
  }

  //查询所有微信好友
  queryFriendsAll(...args) {
    return this.axiosIns.post(this.jwtConfig.queryFriendsAll, qs.stringify(...args))
  }
  //根据好友id查询好友信息
  queryFriendid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryFriendid, qs.stringify(...args))
  }

  //查询所有微信群
  queryChatRoomAll(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChatRoomAll, qs.stringify(...args))
  }
  //根据群号查询群名称
  queryChatRoominfoBychatroomid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChatRoominfoBychatroomid, qs.stringify(...args))
  }

  //根据群成员id查询群成员名称
  queryChatRoommemberBymwechatid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChatRoommemberBymwechatid, qs.stringify(...args))
  }
  //根据群号修改群参数
  updatebychatroomid(...args) {
    return this.axiosIns.post(this.jwtConfig.updatebychatroomid, qs.stringify(...args))
  }

  //查询群发产品的群
  groupconfigAll(...args) {
    return this.axiosIns.post(this.jwtConfig.groupconfigAll, qs.stringify(...args))
  }

  //查询群是否为监听群
  querychatroombytype(...args) {
    return this.axiosIns.post(this.jwtConfig.querychatroombytype, qs.stringify(...args))
  }

  //根据群号查找群成员
  findChatRoomMemberBychatroomid(...args) {
    return this.axiosIns.post(this.jwtConfig.findChatRoomMemberBychatroomid, qs.stringify(...args))
  }

  //设置微信群产品群
  chatroomadd(...args) {
    return this.axiosIns.post(this.jwtConfig.chatroomadd, qs.stringify(...args))
  }

  //更新产品群类别
  chatroomtaskupdate(...args) {
    return this.axiosIns.post(this.jwtConfig.chatroomtaskupdate, qs.stringify(...args))
  }
  //添加产品库
  tbcommodityAdd(...args) {
    return this.axiosIns.post(this.jwtConfig.tbcommodityadd, qs.stringify(...args))
  }
  //查询产品库
  tbcommodityQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.tbcommodityQuery, qs.stringify(...args))
  }

  //删除产品库
  deletebygoodsid(...args) {
    return this.axiosIns.post(this.jwtConfig.deletebygoodsid, qs.stringify(...args))
  }
  //查询微信活码
  querywechatqrcode(...args) {
    return this.axiosIns.post(this.jwtConfig.querywechatqrcode, qs.stringify(...args))
  }
  //查询推广渠道类型
  queryChannelByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChannelByCid, qs.stringify(...args))
  }
  //修改推广用户信息
  updatebystatus(...args) {
    return this.axiosIns.post(this.jwtConfig.updatebystatus, qs.stringify(...args))
  }
  //生成物料
  querymaterialadd(...args) {
    return this.axiosIns.post(this.jwtConfig.querymaterialadd, qs.stringify(...args))
  }
  //物料查询
  querymaterialall(...args) {
    return this.axiosIns.post(this.jwtConfig.querymaterialall, qs.stringify(...args))
  }
  //根据微信号跟好友id查询好友信息/或者群信息
  findContactinfoByWechatidFriendid(...args) {
    return this.axiosIns.post(this.jwtConfig.findContactinfoByWechatidFriendid, qs.stringify(...args))
  }
  //查询推广员列表
  querypromoter(...args) {
    return this.axiosIns.post(this.jwtConfig.querypromoter, qs.stringify(...args))
  }
  //查询公司部门
  querydepartmentbycid(...args) {
    return this.axiosIns.post(this.jwtConfig.querydepartmentbycid, qs.stringify(...args))
  }
  //根据ID删除部门
  deldepartmentbyid(...args) {
    return this.axiosIns.post(this.jwtConfig.deldepartmentbyid, qs.stringify(...args))
  }
  //新增部门
  adddepartment(...args) {
    return this.axiosIns.post(this.jwtConfig.adddepartment, qs.stringify(...args))
  }
  //新增帐号
  addaccount(...args) {
    return this.axiosIns.post(this.jwtConfig.addaccount, qs.stringify(...args))
  }
  //查询员工
  queryAccountsbyCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryAccountsbyCid, qs.stringify(...args))
  }
  //根据ID删除员工帐号
  delAccountsbyid(...args) {
    return this.axiosIns.post(this.jwtConfig.delAccountsbyid, qs.stringify(...args))
  }
  //插入电话号码到用户池
  addphonenum(...args) {
    return this.axiosIns.post(this.jwtConfig.addphonenum, qs.stringify(...args))
  }
  //查询标签对应的用户池有多少人
  queryphonetagnum(...args) {
    return this.axiosIns.post(this.jwtConfig.queryphonetagnum, qs.stringify(...args))
  }

  //添加自动加好友任务
  addfriendtask(...args) {
    return this.axiosIns.post(this.jwtConfig.addfriendtask, qs.stringify(...args))
  }
  //查看自动加粉任务
  GetAutoFriendAddTaskList(...args) {
    return this.axiosIns.post(this.jwtConfig.GetAutoFriendAddTaskList, qs.stringify(...args))
  }
  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

}
