const config = require('./package.json')

export const appName = "小X Scrm管理系统-私域流量的运营专家"//config.productName // app名称

export const appVersion = config.version // 版本号

export const webSocketUrl = 'ws://wxscrm.zhebaihui.net:13188' // websocket地址

export const baseUrl = 'https://wxscrm.zhebaihui.net/api' // 生产环境的baseUrl

export const baseUrlDev = 'http://127.0.0.1:13586' // 开发环境的baseUrl

export const uploadUrl = 'https://wxscrm.zhebaihui.net:10089/fileUpload2020' // 文件服务器地址
