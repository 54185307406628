
export default [
    {
        path: '/business/businessconfig',
        name: 'businessconfig',
        component: () => import('@/views/business/businessconfig.vue'),
        meta: {
            pageTitle: '企业管理',
            breadcrumb: [
                {
                    text: '系统配置',
                    active: true,
                },

            ],
            resource: 'ecom',
            action: 'bus',
        },
    },

    {
        path: '/business/businessmanger',
        name: 'businessmanger',
        component: () => import('@/views/business/businessmanger.vue'),
        meta: {
            pageTitle: '企业管理',
            breadcrumb: [
                {
                    text: '部门管理',
                    active: true,
                },

            ],
            resource: 'ecom',
            action: 'bus',
        },
    },
    {
        path: '/business/staffmanger',
        name: 'staffmanger',
        component: () => import('@/views/business/staffmanger.vue'),
        meta: {
            pageTitle: '企业管理',
            breadcrumb: [
                {
                    text: '人员管理',
                    active: true,
                },

            ],
            resource: 'ecom',
            action: 'bus',
        },
    },

]
