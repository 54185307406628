export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/wx/wxqrcode/',
    name: 'wxqrcode',
    component: () => import('@/views/wx/wxqrcode.vue'),
    meta: {
      layout: 'full',
      resource: 'nologin',
    },
  },
  {
    path: '/wx/promoter/',
    name: 'promoter',
    component: () => import('@/views/wx/promoter.vue'),
    meta: {
      layout: 'full',
      resource: 'nologin',
    },
  },
  {
    path: '/wx/test/',
    name: 'promoter',
    component: () => import('@/views/wx/test.vue'),
    meta: {
      layout: 'full',
      resource: 'nologin',
    },
  },
  {
    path: '/admin/wechatlist',
    name: 'wechatlist',
    component: () => import('@/views/admin/WeChatList.vue'),
    meta: {
      resource: 'wechat',
      action: 'read',
    },
  },
  {
    path: '/admin/WechatEdit/:id',
    name: 'WechatEdit',
    component: () => import('@/views/admin/WechatEdit.vue'),
    meta: {
      resource: 'wechat',
      action: 'read',
    },
  },
  {
    path: '/admin/Wxcontactinfo',
    name: 'Wxcontactinfo',
    component: () => import('@/views/admin/Wxcontactinfo.vue'),
    meta: {
      resource: 'wechat',
      action: 'read',
    },
  },
  {
    path: '/admin/WechatMessages',
    name: 'WechatMessages',
    component: () => import('@/views/admin/WechatMessages.vue'),
    meta: {
      resource: 'wechat',
      action: 'read',
    },
  },
  {
    path: '/admin/ChatRoomManger/:wechatid',
    name: 'ChatRoomManger',
    component: () => import('@/views/admin/ChatRoomManger.vue'),
    meta: {
      resource: 'wechat',
      action: 'read',
    },
  },
]
