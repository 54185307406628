export default [
  {
    path: '/rebate/GroupConfig',
    name: 'GroupConfig',
    component: () => import('@/views/rebate/GroupConfig.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '群发配置',
          active: true,
        },
        
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/TbCommodity',
    name: 'TbCommodity',
    component: () => import('@/views/rebate/TbCommodity.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '产品列表',
          active: true,
        },
        
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
]
