export default [
  {
    path: '/marketing/material',
    name: 'material',
    component: () => import('@/views/marketing/material.vue'),
    meta: {
      pageTitle: '营销管理',
      breadcrumb: [
        {
          text: '物料管理',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/promoterlist',
    name: 'promoterlist',
    component: () => import('@/views/marketing/promoterlist.vue'),
    meta: {
      pageTitle: '营销管理',
      breadcrumb: [
        {
          text: '推广员管理',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/userpool',
    name: 'Userpool',
    component: () => import('@/views/marketing/userpool.vue'),
    meta: {
      pageTitle: '营销管理',
      breadcrumb: [
        {
          text: '用户池',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/addpeople',
    name: 'addpeople',
    component: () => import('@/views/marketing/addpeople.vue'),
    meta: {
      pageTitle: '营销管理',
      breadcrumb: [
        {
          text: '任务管理',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/taskmanagement',
    name: 'taskmanagement',
    component: () => import('@/views/marketing/taskmanagement.vue'),
    meta: {
      pageTitle: '营销管理',
      breadcrumb: [
        {
          text: '加粉任务',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/addpeople',
    name: 'addpeople',
    component: () => import('@/views/marketing/addpeople.vue'),
    meta: {
      pageTitle: '营销管理',
      breadcrumb: [
        {
          text: '导入用户信息',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  
]
