import base64 from '@/utils/base64.js'
import { sendSock } from '@/websocket/websocket'

/**
 * 备(手机客户端、客服客户端)获取通信token请求
 * 回调 DeviceAuthRsp
 * @param {String} userName 用户名
 * @param {String} password 密码
 */
export function DeviceAuthReq(userName, password) {
  console.log('调用DeviceAuthReq')
  const msg = {
    Id: 1010, // 非必须
    AccessToken: '', // 非必须
    MsgType: 'DeviceAuthReq',
    Content: {
      AuthType: 2,
      Credential: base64.encode(userName + ':' + password)
    }
  }
  sendSock(msg)
}

/**
 * 向服务端发心跳
 * @param {String} token token
 */
export function HeartBeatReq(token) {
  const msg = {
    Id: 1001,
    MsgType: 'HeartBeatReq',
    AccessToken: token,
    Content: {
      token: token
    }
  }
  sendSock(msg)
}

/**
 * 推送指定微信的会话列表
 * 返回 ConversationPushNotice
 * @param {String} wechatId 微信id
 * @param {Number} startTime 时间
 */
export function TriggerConversationPushTask(wechatId, startTime) {
  if (!wechatId) return
  if (!startTime) {
    // startTime = 0
    startTime = new Date().getTime() - 30 * 24 * 3600 * 1000
  }
  const msg = {
    Id: 1232,
    MsgType: 'TriggerConversationPushTask',
    Content: { WeChatId: wechatId, StartTime: startTime }
  }
  sendSock(msg)
}

/**
 * 查询手机状态
 * @param {String} wechatId 商家个人微信内部全局唯一识别码
 */
export function PhoneStateTask(wechatId) {
  const msg = {
    MsgType: 'PhoneStateTask',
    Content: { WeChatId: wechatId }
  }
  sendSock(msg)
}
