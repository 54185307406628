export default {
  // Endpoints
  loginEndpoint: '/user/login',
  queryDevicesAll: '/admin/device/pageList',
  queryDevices: '/user/device/pageList',
  deleteDevices: '/user/device/deletes',
  findDeviceStateInfoByid: '/user/device/findDeviceStateInfoByid',
  queryByCid: '/user/department/queryByCid',
  getAllAccountInfoByTypeCidDid: '/user/account/getAllAccountInfoByTypeCidDid',
  updateDevice: '/user/device/update',
  addDevice: '/user/device/add',
  queryFriendsAll: '/user/friends/pageList',
  queryChatRoomAll: '/chatroommanger/pageList',
  queryChatRoominfoBychatroomid: '/chatroommanger/queryChatRoominfoBychatroomid',
  queryChatRoommemberBymwechatid: '/chatroommanger/queryChatRoommemberBymwechatid',
  updatebychatroomid: '/chatroommanger/updatebychatroomid',
  querychatroombytype: '/chatroommanger/querychatroombytype',
  findChatRoomMemberBychatroomid: '/chatroommanger/findChatRoomMemberBychatroomid',
  groupconfigAll: '/group/config/pageList',
  chatroomadd: '/group/config/add',
  chatroomtaskupdate: '/group/config/update',
  tbcommodityadd: '/rebate/tbcommodityadd',
  tbcommodityQuery: '/rebate/pageList',
  deletebygoodsid: '/rebate/deletebygoodsid',
  findContactinfoByWechatidFriendid: '/user/friends/findContactinfoByWechatidFriendid',
  querymaterialall: '/material/pageList',
  querymaterialadd: '/material/insert',
  querywechatqrcode: '/wx/wechatqrcode',
  queryChannelByCid: '/wx/queryChannelByCid',
  updatebystatus: '/wx/updatebystatus',
  queryFriendid: '/wx/findContactinfoByfriendid',
  querypromoter: '/promoter/pageList',
  querydepartmentbycid: '/user/department/queryByCid',
  deldepartmentbyid: '/user/department/deletes',
  adddepartment: '/user/department/add',
  addaccount: '/user/account/add',
  queryAccountsbyCid: '/user/account/queryByCid',
  delAccountsbyid: '/user/account/deletes',
  addphonenum: '/phone/addPhonenum',
  queryphonetagnum: '/phone/queryphonebytagnum',
  addfriendtask: '/phone/addfriendtask',
  GetAutoFriendAddTaskList:'/pc/GetAutoFriendAddTaskList',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
